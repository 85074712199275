import React, { useState, useEffect } from 'react';
import '../Fonts/fontello-icon.css';
import '../global.css';
import axios from 'axios';

const Footer = () => {
  const [scrolling, setScrolling] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [logoContent, setLogoContent] = useState(null);
  const [bgColor, setBgColor] = useState("");
  const [fontColor, setFontColor] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  const subDomain = localStorage.getItem('subDomain');
  let schoolName = subDomain;

  if (subDomain.includes('virtupreplabs')) {
    schoolName = 'torah';
  }
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initialize based on current window width

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://membership.virtupreplabs.com/school/v1/getFooterData', {
          params: { school: schoolName },
        });
        if (response.data.success === false) {
          setError(response.data.message);
        } else {
          setData(response.data);
          setFontColor(response.data.fontColor);
          setBgColor(response.data.backgroundColor);
          const logoUrl = response.data.logo.logo;
          if (logoUrl.endsWith('.svg')) {
            fetchAndModifySVG(logoUrl,response.data.fontColor);
            modifyVP3dLogo(response.data.fontColor);
          } else {
            setLogoContent(logoUrl);
            modifyVP3dLogo(response.data.fontColor);
          }
        }
      } catch (err) {
        setError("Error loading footer data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [schoolName]);

  const fetchAndModifySVG = async (svgUrl, fontColor) => {  // Accept fontColor as a parameter
    try {
      const response = await fetch(svgUrl);
      const svgText = await response.text();
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
      const svgElement = svgDoc.querySelector('svg');
      
      if (svgElement) {
        const paths = svgElement.querySelectorAll('path');
        paths.forEach(path => {
          path.setAttribute('fill', fontColor);   // Use the passed fontColor
          path.setAttribute('stroke', fontColor); 
        });
        svgElement.setAttribute('width', '50px');
        svgElement.setAttribute('height', '20px');
        const serializer = new XMLSerializer();
        const modifiedSvgText = serializer.serializeToString(svgElement);
        setLogoContent(modifiedSvgText);
      }
    } catch (error) {
      console.error('Error fetching or modifying the SVG:', error);
    }
  };

  const modifyVP3dLogo = (color) => {
    fetch(`${process.env.PUBLIC_URL}/logos/VirtuPrepIcon.svg`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Failed to load SVG: ${response.statusText}`);
        }
        return response.text();
      })
      .then((svgText) => {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgText, "image/svg+xml");
        const svgElement = svgDoc.documentElement;
  
        // Modify the size of the SVG
        svgElement.setAttribute('width', '30px');
        svgElement.setAttribute('height', '30px');
  
        // Modify the color of specific elements in the SVG
        svgElement.querySelectorAll('path, rect, circle').forEach((element) => {
          // Remove any existing inline styles and fill attributes
          element.removeAttribute('style');
          element.removeAttribute('fill');
  
          // Set new fill color and ensure full opacity
          element.setAttribute('fill', color);
          element.setAttribute('fill-opacity', '1'); // Ensures full opacity
        });
  
        // Serialize the updated SVG back to a string
        const updatedSVG = new XMLSerializer().serializeToString(svgElement);
  
        // Insert the modified SVG into the DOM
        const logoContainer = document.getElementById('vp3d-logo-container');
        logoContainer.innerHTML = '';  // Clear the container first
        logoContainer.innerHTML = updatedSVG;
      })
      .catch((error) => {
        console.error("Error fetching and modifying VP3d.svg:", error);
      });
  };
  
  

    

  
  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Use smooth scrolling
    });
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClick = () => {
    window.open('https://virtupreplabs.com', '_blank');
  };

  const dropdownMenuClassName = `dropdown-menu border-0 ${scrolling ? '' : 'bg-transparent'}`;

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!data || data === " ") return <div>...Loading</div>;

  const filteredMenu = data.menu.filter(item => {
    if (item.name === 'Writings' && subDomain !== 'virtupreplabs') {
      return false;
    }
    return true;
  });

  return (
    <footer className="footer-padding pt-8rem" style={{ backgroundColor: bgColor || "black", color: fontColor || "#ffe6c8" ,marginTop:'5rem'}}>
      <div className="footer-container vp-container">
        <div className="d-flex flex-column flex-lg-row justify-content-between align-items-start">
          <div className="logo-container d-inline-flex align-items-center gap-2 maxw-265 w-100 me-xl-5">
            <div className="siteLogo">
              <a href="/">
                {logoContent ? (
                  logoContent.includes('<svg') ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: logoContent }}
                      className="svg-rotate"
                      style={{ display: 'block' }}
                    />
                  ) : (
                    <img src={logoContent} alt="Logo" width="50px" height="20px" />
                  )
                ) : (
                  <i className={data.logo.iconClass}></i>
                )}
              </a>
            </div>
            <a className="navbar-brand common-textshadow montser-lrg" href={data.logo.brandHref}>
              <span className='common-textshadow montser-lrg' style={{color: fontColor}}>{data.logo.brandName}</span>
            </a>
          </div>

          {/* Main Menu Row */}
          <div className="d-flex flex-wrap justify-content-between w-100">
            <ul className="navbar-nav d-flex flex-row gap-4 mb-2 mb-lg-0">
              {/* Display Educator, Feature, and Research in a single row */}
              {filteredMenu.slice(0, 3).map((item, index) => (
                <li className="nav-item" key={index}>
                  {!item.subMenu ? (
                    <a className="nav-link common-textshadow montser-lrg" href={item.href}>
                      <span className="text-decorator-underline common-textshadow montser-lrg" style={{color: fontColor}}>{item.name}</span>
                    </a>
                  ) : (
                    <div className="nav-item dropdown">
                      <a
                        className="nav-link d-flex align-items-center gap-2 montser-mdm-2 common-textshadow"
                        href="/"
                        id={`navbarDropdown${index}`}
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={handleDropdownToggle}
                      >
                        <span className="text-decorator-underline common-textshadow montser-lrg" style={{color: fontColor}}>{item.name}</span>
                        <div className={`${isDropdownOpen ? 'rotate-upwards d-flex align-center' : 'd-flex align-center'}`}>
                          <i className="demo-icon icon-opened-folder dropdown-icon-size" style={{color:fontColor}}></i>
                        </div>
                      </a>
                      <ul className={dropdownMenuClassName} aria-labelledby={`navbarDropdown${index}`} style={{backgroundColor:localStorage.getItem("heroFontColor")}}>
                        {item.subMenu.map((subItem, subIndex) => {
                          const hrefPrefix = item.name === 'Features' ? 'feature' : 'educator';
                          return (
                            <li key={subIndex}>
                              <a className="dropdown-item-effect montser-mdm-2" style={{color: localStorage.getItem("herobgColor")}} href={`/${hrefPrefix}=${subItem.name}`}>
                                {subItem.name}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>

          {/* Remaining Menu Items */}
          <ul className="navbar-nav flex-wrap d-flex align-items-start me-auto mb-2 mb-lg-0 gap-sm-4 gap-2 footer-menu w-100">
            {filteredMenu.slice(3).map((item, index) => (
              <li className="nav-item nav-item-space" key={index}>
                {!item.subMenu ? (
                  <a className="nav-link montser-lrg common-textshadow" href={item.href}>
                    <span className="text-decorator-underline common-textshadow montser-lrg">{item.name}</span>
                  </a>
                ) : (
                  <div className="nav-item dropdown">
                    <a
                      className="nav-link d-flex align-items-center gap-2 montser-lrg common-textshadow"
                      href="/"
                      id={`navbarDropdown${index}`}
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={handleDropdownToggle}
                    >
                      <span className="text-decorator-underline common-textshadow montser-lrg" style={{color: fontColor}}>{item.name}</span>
                      <div className={`${isDropdownOpen ? 'rotate-upwards d-flex align-center' : 'd-flex align-center'}`}>
                        <i className="demo-icon icon-opened-folder dropdown-icon-size" style={{color:fontColor}}></i>
                      </div>
                    </a>
                    <ul className={dropdownMenuClassName} aria-labelledby={`navbarDropdown${index}`}>
                      {item.subMenu.map((subItem, subIndex) => {
                        const hrefPrefix = item.name === 'Features' ? 'feature' : 'educator';
                        return (
                          <li key={subIndex}>
                            <a className="dropdown-item-effect montser-lrg common-textshadow" href={`/${hrefPrefix}=${subItem.name}`} style={{color: fontColor}}>
                              {subItem.name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>

       {/* Logo and Social Links */}
       <div className={`mt-4 vp-container ${isMobile ? '' : 'd-flex justify-content-between'}`} style={{ zIndex: 0 }}>
        {/* Left Content */}
        <div>
  {isMobile ? (
    <div style={{ display: 'flex', alignItems: 'center' }} onClick={handleClick}>
      <div id="vp3d-logo-container" className="vp-logo"></div>
      <div className="ms-4" style={{textAlign: 'left'}}>
        <span className="montser-mdm common-textshadow">Powered by </span>&nbsp;
        <strong className="montser-lrg common-textshadow">Virtu Prep</strong>
      </div>
    </div>
  ) : (
    <div className="text-start d-flex align-items-center" onClick={handleClick}>
      <div id="vp3d-logo-container" className="vp-logo"></div>
      <a className='text-decoration-none text-reset cursor-pointer'>
        <span className='montser-lrg common-textshadow'>
          Powered by <strong className='montser-lrg common-textshadow'>Virtu Prep</strong>
        </span>
      </a>

    </div>

  )}
</div>

        {/* Right Content */}
        <div className="text-end">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 flex-row gap-4">
            {data.socialLinks.map((link, index) => (
              <li className="nav-item" key={index}>
                <a className="nav-link copy-right-text text-decorator-underline common-textshadow" href={link.href} target="_blank" rel="noopener noreferrer">
                  <span className="text-decorator-underline montser-lrg common-textshadow">{link.name}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Back to Top Button */}
      <div className="text-end mt-4">
        <button className="btn btn-primary montser-lrg common-boxshadow" onClick={scrollToTop}>Back to Top</button>
      </div>
    </footer>
  );
};


export default Footer;
